<template>
  <div>
    <label :for="id" class="w-full block">
      <div class="flex rounded-md shadow-sm">
        <div class="relative flex items-stretch flex-grow focus-within:z-10">
          <input :value="name" readonly type="text" class="focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" :placeholder="placeholder" />
        </div>
        <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <CameraIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </label>
    <input
      @change="onFileChange"
      class="hidden"
      :id="id"
      type="file"
      accept="image/*"
    >
  </div>
</template>

<script>
  import { CameraIcon } from '@heroicons/vue/24/outline'
  import watermark from 'watermarkjs'

  export default {
    components: {
      CameraIcon,
    },
    emits: ['file-change'],
    props: {
      id: String,
      placeholder: String,
      watermarkText: {
        type: String,
        default: ''
      },
      index: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        name: "",
        image: null
      }
    },
    methods: {
      onFileChange (e) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        this.name = files[0].name
        this.createImage(files[0])
      },
      createImage (file) {
        watermark([file]).dataUrl(
          watermark.text.lowerRight(this.watermarkText, '100px Josefin Slab', '#fff', 0.5)
        ).then((img) => {
          this.image = img
          this.$emit('file-change', {image: this.image, name: this.name, id: this.index})
        })
        // var image = new Image()
        /*let reader = new FileReader()
        reader.onload = (e) => {
          console.log('length: ', e.target.result.includes('data:image/jpeg'))
          this.image = e.target.result
          this.$emit('file-change', {image: this.image, name: this.name, id: this.index})
        }
        reader.readAsDataURL(file)*/
      }
    }
  }
</script>
