<template>
  <div class="fixed bottom-4 right-4 z-10">
    <button v-on:click="addService" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
        {{ !showScanner ? $t('installation.performService') : $t("cancel")}}
        <CogIcon class="h-5 w-5 ml-2" />
    </button>
  </div>
  <div v-if="showScanner" class="px-4 sm:px-0">
    <div class="min-full-screen flex flex-col justify-center">
      <div v-if="deviceTemplates.length > 1" class="mb-4">
        <NativeSelect
          v-model = "deviceTemplateId"
          :placeholder="$t('device.selectDeviceType')"
          :options="deviceTemplatesOptions"
        />
      </div>
      <BarcodeScanner
        :disabled="deviceTemplates.length > 1 && !deviceTemplateId"
        :text="$t('home.barcodeScanner.insertSerialNumber')"
        :placeholder="$t('home.barcodeScanner.placeholder')"
        v-model="serialNumber"
        @scan-completed="checkSerialNumber"
      />
    </div>
  </div>
  <Services
    v-if="!showScanner"
    stickyTop="64px"
  />
  <Modal
    ref="modal"
    :title="serialNumber"
    :text="modalText"
    :cancel-button-label="$t('cancel')"
  >

    <button
      v-if="modalAction === 'new-device'"
      type="button" class="modal-action-button"
      @click="$router.push({path: `/add-device-simple/new`})"
    >
        {{$t("installation.addToDatabase")}}
    </button>
    <button
      v-if="modalAction === 'found-device'"
      type="button" class="modal-action-button"
      @click="$router.push({
        path: `/installations/new/add-device/${device.id}`,
        query: {
          offline: modalAction === 'offline' ? true : null,
        }
      })"
    >
      {{$t('device.initiate')}}
    </button>
  </Modal>

  <Modal
    ref="serviceModal"
    :title="serialNumber"
    :text="$t('device.notFinished')"
  >
    <button
      type="button" class="modal-action-button"
      @click="$router.push({path: `/services/${serviceId}`})"
    >
      {{$t("yes")}}, {{$t("device.finishService")}} 
    </button>
    <button
      type="button" class="mt-3 modal-action-button"
      @click="$router.push({path: `/services/new/device/${serialNumber}`})"
    >
      {{$t("no")}}, {{$t("device.startNewService")}}
    </button>
  </Modal>
</template>
<script>
import Services from '@/components/services/Services.vue'
import BarcodeScanner from '@/components/inputs/BarcodeScanner.vue'
import NativeSelect from '@/components/inputs/NativeSelect.vue';
import Modal from '@/components/layout/Modal.vue';
import { api } from '@/assets/js/api-client.js'
import { CogIcon } from "@heroicons/vue/24/outline"
import { mapState } from 'vuex'

export default {
  components: {
      Services,
      BarcodeScanner,
      NativeSelect,
      Modal,
      CogIcon
  },
  data () {
    return {
      serialNumber: '',
      showScanner: false,
      modalText: '',
      modalAction: '',
      deviceTemplateId: process.env.VUE_APP_IFDEVICE_TEMPLATE_ID
    }
  },
  computed: {
    ...mapState(['user', 'device', 'deviceTemplates']),
    deviceTemplatesOptions () {
      const options = this.deviceTemplates
        .filter(template => {return template.isActive && this.user.availableTemplates.includes(`/device_templates/${template.id}`)})
        .map(template => {return {name: template.name, value: template.id}})
      return options
    },
    deviceTemplate () {
      return this.deviceTemplates.find(template => {return template.id == this.deviceTemplateId})
    },
    isCustomDevice () {
      return this.deviceTemplateId && this.deviceTemplateId != 0
    }
  },
  mounted () {
      if (this.deviceTemplatesOptions[0]) this.deviceTemplateId = this.deviceTemplatesOptions[0].value
    },
  methods: {
    addService () {
      this.showScanner = !this.showScanner
    },
    async checkSerialNumber () {
      if (this.serialNumber === ""){
        this.$notify({
          title: this.$t('home.inspection.errorTitle'),
          text: this.$t('home.barcodeScanner.emptySerialNumberMessage'),
          type: 'error',
          duration: 5000
        })
        return
      }
      if (await this.$store.dispatch('checkAsp')) return
      this.$store.dispatch('setLoading', true)

      const params = {
        id: this.serialNumber,
        deviceTemplate: this.deviceTemplate
      }

      this.$store.dispatch('getDevice', params).then((result) => {
        this.$store.dispatch('setLoading', false)
        console.log(result, this.device)
        if (result.status === 'error') {
          this.$notify({
            title: this.$t("public.errors.dbConnectionError"),
            text: result.error,
            type: 'error',
            duration: 15000
          })
        } else if (result.status === 'not-found') {
          const idField = this.deviceTemplate.activeVersion.parametersSchema.find(field => {return field.barcodeScanner})
          this.$store.dispatch('saveDevice', {
            parameters: {
              [idField.name]: this.serialNumber
            },
            deviceTemplate: this.deviceTemplate
          })
          this.modalText = `${this.$t('service.serviceInfoText1')} ${this.deviceTemplate.name} ${this.$t('service.serviceInfoText2')} ${process.env.VUE_APP_ORG_NAME} ${this.$t('service.serviceInfoText3')}`
          this.modalAction = "new-device"
          this.$nextTick(() => { this.$refs.modal.show() })
        } else if (this.device.installation) {
          this.$nextTick(() => this.goToService())
        }
        else {
          this.modalText = this.$t('device.foundNotInstalled')
          this.modalAction = "found-device"
          this.$nextTick(() => { this.$refs.modal.show() })
        }
      }).catch(() => {
        this.$store.dispatch('setLoading', false)
        const idField = this.deviceTemplate.activeVersion.parametersSchema.find(field => {return field.barcodeScanner})
        this.$store.dispatch('saveDevice', {
          parameters: {
            [idField.name]: this.serialNumber
          },
          deviceTemplate: this.deviceTemplate
        })
        this.$notify({
          title: this.$t('public.infos.noInternetConnection'),
          text: '',
          type: 'error',
          duration: 15000
        })
      })
    },
    async goToService () {
      this.$refs.modal.close()
      this.$nextTick(() => {
        this.$store.dispatch('setLoading', true)
      })
      const result = await api.get(`/services?status=spare_parts_sent&customDevice.id=${this.device.id}`)
      const pendingServices = result.data
      this.$nextTick(() => this.$store.dispatch('setLoading', false))
      if (pendingServices.length) {
        this.serviceId = pendingServices[0].id
        this.$nextTick(() => { this.$refs.serviceModal.show() })
      } else {
        this.$router.push({
          path: `/services/new/device/${this.device.id}`
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .min-full-screen{
    min-height: calc(100vh - 194px);
  }
</style>
