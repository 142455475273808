<template>
  <div class="max-w-7xl mx-auto pb-24">
    <TemplateForm
      ref="templateForm"
      :preview="false"
      formType="inspection"
      :schema="inspectionDevice.schemaVersion.inspectionFormSchema"
      :allFields="fieldsFlat"
      :props-device="inspectionDevice"
      :newInstallation="false"
      :inspection="inspection"
      @submit="sendInspectionPdf"
    />
    <Modal
      ref="modal"
      :title="$t('home.inspection.installationCardCreated')"
      :text="$t('home.inspection.installationCardText')"
      :cancel-button-label="$t('home.inspection.correctLabelBtn')"
    >
      <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="sendInspectionPdf">
        {{$t("submit")}}
      </button>
    </Modal>
  </div>
</template>

<script>
import TemplateForm from "@/components/admin/FormTemplate/TemplateForm.vue";
import Modal from '@/components/layout/Modal.vue';
import { getDeviceSerialNumber, s3url, serializeParams } from '@/assets/js/helpers.js'
import { mapState } from 'vuex'

export default {
  name: "AddDevice",
  components: {
    TemplateForm,
    Modal
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['user', 'inspections', 'installations']),
    inspection () {
      if (this.inspections) {
        let inspection = this.inspections.pending.data.find((insp) => { return insp.id === parseInt(this.$route.params.id) })
        if (!inspection) inspection = this.inspections.scheduled.data.find((insp) => { return insp.id === parseInt(this.$route.params.id) })
        return inspection
      } else {
        return null
      }
    },
    inspectionDevice () {
      console.log(this.inspection)
      if (this.inspection) {
        const device = serializeParams(this.inspection.customDevice)
        return device
      } else {
        return null
      }
    },
    installation () {
      return this.inspectionDevice.installation
    },
    fieldsFlat() {
      const schema = this.inspectionDevice.schemaVersion.inspectionFormSchema
      return schema ? schema.map((step, stepIndex) => {
        return step.schema.map(field => {return {...field, stepIndex: stepIndex}})
      }).flat(1) : []
    }
  },
  methods: {
    sendInspectionPdf (formData) {
      // this.$nextTick(() => { this.$store.dispatch('setLoading', true) })

      const action = 'update'
      const executionDate = formData.executionDate

      const data = {
        id: parseInt(this.$route.params.id),
        status: 'done',
        executionDate: executionDate.toLocaleString('sv').slice(0,10),
        executionTime: executionDate.getTime().toString()
      }

      const emails = [ this.installation.installer.email ]
      if (this.installation.receivers) this.installation.receivers.forEach(receiver => {emails.push(receiver.email)})
      if (this.installation.customerEmail) emails.push(this.installation.customerEmail)

      const emailData = {
        organisation: process.env.VUE_APP_ORG_ID,
        device_id: getDeviceSerialNumber(this.inspectionDevice),
        inspection_card: `${s3url}${this.inspectionDevice.id}-${data.executionTime}-inspection-card.pdf`,
        execution_date: executionDate.toLocaleDateString('pl-PL'),
        email: emails,
        language: this.installation.language
      }

      console.log(emailData)

      let request = {
        status: 0,
        endpoint: `/inspections`,
        type: 'inspection',
        state: 'online',
        incognito: null,
        action: action,
        files: formData.files,
        data: data,
        executionTime: executionDate.getTime(),
        installerId: this.user.id,
        emailRequest: {
          type: 'email',
          data: emailData,
          action: 'inspection_card',
          installerId: this.user.id
        }
      }

      this.$store.dispatch('addRequest', request)
      this.$router.push({path: '/'})
    }
  },
  mounted(){
    this.$store.dispatch('saveCurrentInstallation', this.inspectionDevice.installation)
  },
};
</script>
